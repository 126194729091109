<template>
  <div style="padding-bottom: 1rem">
    <top-fixed></top-fixed>
    <div class="account" style="text-align: center">
      <img style="width: 10rem; margin: 0.5rem 0" src="../assets/img2/T.png" />
      <div class="account_box2">
        <div style="font-size: 1.4rem; font-weight: 600; margin-bottom: 0.3rem">
          {{ useData.usdt_balance }}
        </div>
        <div style="font-size: 0.4rem">{{ $t("hj2") }} (USDT)</div>
      </div>

      <div style="display: flex; padding: 0 5%; margin-top: 1.5rem">
        <div class="cent_but" @click="toDetail(2)">{{ $t("hj51") }}</div>
        <div class="cent_but" @click="toDetail(1)">{{ $t("hj52") }}</div>
      </div>
    </div>

    <div
      class="account_box3"
      style="padding: 1.5rem 0.6rem; position: relative; top: -2rem"
    >
      <div style="display: flex">
        <img
          style="width: 1.8rem; margin-right: 0.5rem; height: 1.8rem"
          src="../assets/img2/ye.png"
        />
        <div
          style="
            width: 80%;
            border-bottom: 0.5px dashed #e7c7a8;
            padding-bottom: 1rem;
          "
        >
          <div
            style="line-height: 0.8rem; font-size: 0.8rem; margin-bottom: 5px"
          >
            {{ Number(useData.token_balance).toFixed(2) }}
          </div>
          <div style="font-size: 0.6rem; color: #999999">
            {{ $t("hj4") }}(USDT)
          </div>
        </div>
      </div>
      <div style="display: flex; padding-top: 1rem">
        <img
          style="width: 1.8rem; margin-right: 0.5rem; height: 1.8rem"
          src="../assets/img2/zsy.png"
        />
        <div
          style="
            width: 80%;
            border-bottom: 0.5px dashed #e7c7a8;
            padding-bottom: 1rem;
          "
        >
          <div
            style="line-height: 0.8rem; font-size: 0.8rem; margin-bottom: 5px"
          >
            {{ Number(useData.total_eth_profit).toFixed(2) }}
          </div>
          <div style="font-size: 0.6rem; color: #999999">
            {{ $t("hj28") }}(ETH)
          </div>
        </div>
      </div>
      <div style="display: flex; padding-top: 1rem">
        <img
          style="width: 1.8rem; margin-right: 0.5rem; height: 1.8rem"
          src="../assets/img2/jh.png"
        />
        <div
          style="
            width: 80%;
            border-bottom: 0.5px dashed #e7c7a8;
            padding-bottom: 1rem;
          "
        >
          <div
            style="line-height: 0.8rem; font-size: 0.8rem; margin-bottom: 5px"
          >
            {{ Number(useData.available_eth_profit).toFixed(2) }}
          </div>
          <div style="font-size: 0.6rem; color: #999999">
            {{ $t("hj3") }}(ETH)
          </div>
        </div>
      </div>
    </div>

    <div
      class="tixian_box"
      style="
        margin: -0.5rem 0.25rem;
        margin-bottom: 2rem;
        padding: 0.5rem;
        background-color: #fff;
        border-radius: 10px;
      "
      v-if="type == 2"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 0.6rem;
          margin-top: 0.11rem;
        "
      >
        <div style="font-weight: 600">{{ $t("hj7") }}</div>
        <div
          @click="type = 1"
          style="color: #1d76fb; display: flex; align-items: center"
        >
          <img
            style="width: 0.6rem; margin-right: 0.3rem"
            src="../assets/img2/tx.png"
          />
          <span style="font-weight: 600; color: #fa9d45">{{ $t("hj6") }}</span>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-top: 0.35rem;
          height: 2rem;
          align-items: center;
          padding: 0 0.3rem;
          border: 1px solid #cac9c9;
          border-radius: 20px;
        "
      >
        <img style="width: 0.8rem" src="../assets/img2/ETH.png" />
        <div>ETH</div>
        <div>
          <input type="text" v-model="dhMoney" />
        </div>
        <div @click="allBut" style="display: flex; align-items: center">
          <span style="color: #fa9d45">{{ $t("hj8") }}</span>
          <img
            style="width: 0.2rem; margin-left: 0.2rem"
            src="../assets/img/arrow_bottom.png"
            alt=""
          />
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-top: 0.35rem;
          height: 2rem;
          align-items: center;
          padding: 0 0.3rem;
          border: 1px solid #cac9c9;
          border-radius: 20px;
        "
      >
        <img style="width: 0.8rem" src="../assets/img2/usdt.png" />
        <div style="position: relative">USDT</div>
        <div>
          <input type="text" v-model="dhUsdt" :disabled="true" />
        </div>

        <div style="width: 1.5rem"></div>
      </div>

      <div @click="exchange" class="cent_but2">
        {{ $t("hj7") }}
      </div>
    </div>

    <div
      class="tixian_box"
      style="
        margin: -0.5rem 0.25rem;
        margin-bottom: 2rem;
        padding: 0.5rem;
        background-color: #fff;
        border-radius: 10px;
      "
      v-if="type == 1"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 0.6rem;
          margin-top: 0.11rem;
        "
      >
        <div style="font-weight: 600">{{ $t("hj6") }}</div>
        <div
          @click="type = 2"
          style="color: #1d76fb; display: flex; align-items: center"
        >
          <img
            style="width: 0.6rem; margin-right: 0.3rem"
            src="../assets/img2/tx.png"
          />
          <span style="font-weight: 600; color: #fa9d45">{{ $t("hj7") }}</span>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-top: 0.35rem;
          height: 2rem;
          align-items: center;
          padding: 0 0.3rem;
          border: 1px solid #cac9c9;
          border-radius: 20px;
        "
      >
        <img style="width: 0.8rem" src="../assets/img2/usdt.png" />
        <span>USDT</span>
        <div>
          <input
            type="text"
            v-model="txMoney"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          />
        </div>
        <div style="display: flex; align-items: center">
          <span @click="allBut" style="color: #fa9d45">{{ $t("hj8") }}</span>
          <img
            style="width: 0.2rem; margin-left: 0.2rem"
            src="../assets/img/arrow_bottom.png"
            alt=""
          />
        </div>
      </div>

      <div class="cent_but2" @click="withdraw" style="width: 100%">
        {{ $t("hj6") }}
      </div>
    </div>
  </div>
</template>


<script>
import TopFixed from "../components/TopFixed.vue";
import { account, withdraw, exchange } from '../http/api'
import { Toast } from 'vant-green';
export default {
  name: "AccountView",
  data () {
    return {
      type: 1, // 1提现 2//兑换
      useData: "",
      dhMoney: '',
      txMoney: '',
      dhUsdt: ''
    }
  },
  components: { TopFixed },
  watch: {
    dhMoney () {
      this.dhUsdt = this.dhMoney * window.localStorage.getItem("exchange_rate")
    }
  },

  methods: {
    toDetail (value) {
      this.$router.push({
        path: "/reward",
        query: {
          type: value
        }
      })
    },

    fetch_Account () {
      account().then(res => {
        this.useData = res.data
      })
    },
    allBut () {
      this.txMoney = parseInt(this.useData.usdt_balance)
      this.dhMoney = this.useData.available_eth_profit
      this.dhUsdt = this.useData.available_eth_profit * window.localStorage.getItem("exchange_rate")
    },

    withdraw () {
      withdraw({ usdt_amount: this.txMoney }).then((res) => {
        this.fetch_Account()
        Toast({
          message: res.msg,
          size: 'large'
        });
      })
    },

    exchange () {
      var data = {
        eth_amount: this.useData.available_eth_profit,
        usdt_amount: this.dhUsdt,
        exchate_rate: window.localStorage.getItem("exchange_rate")
      }
      exchange({ data }).then((res) => {
        this.fetch_Account()
        Toast({
          message: res.msg
        });
      })
    }
  },
  mounted () {
    this.fetch_Account()
  }

};

</script>
<style scoped lang="scss">
.account {
  height: 22rem;
  background: url("../assets/img2/Account_bg.png");
  background-size: 100%;
}
.account_box2 {
  padding: 1rem 0;
  background-image: linear-gradient(
    92deg,
    rgba(255, 255, 255, 0) 3%,
    #ffffff 49%,
    rgba(255, 255, 255, 0) 99%
  );
}
input {
  background: none;
  outline: none;
  border: none;
}
.cent_but {
  background: url("../assets/img2/home_but.png");
  background-size: 100% 100%;
  line-height: 2rem;
  width: 45%;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}
.cent_but2 {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 1rem;
  background-image: linear-gradient(180deg, #fffce9, #ffc782 73%, #ffb871);
  line-height: 2rem;
  border-radius: 50px;
}
.account_box3 {
  background-image: linear-gradient(158deg, #fff7ec 11%, #ffecd5 93%);
  margin: 0 0.3rem;
  border: 1px solid #ffffff;
  border-radius: 20px;
}
.tixian_box {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  margin-bottom: 2rem;
}
</style>
